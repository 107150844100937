import config from "./config";
import axios from "axios";
import {IAppConfig} from "../context/AppMenuProvider";

/**
 * Get API endpoint
 * @param appConfig
 */
const getApiEndpoint = (appConfig: IAppConfig): string => {
    return appConfig.baseUrl + config.apiFeauturedBlock;
};

/**
 * Load intro tile
 * @param appConfig
 */
const getIntroTitle = async (appConfig: IAppConfig): Promise<string> => {
    const res = await axios.get(getApiEndpoint(appConfig));
    return res.data;
};

export default getIntroTitle;
