import React, { useCallback, useContext, useState } from "react";
import { AppMenuContext, IAppMenu } from "../../context/AppMenuProvider";
import { IAppComponentProps } from "../../resources/component-interface";
import MenuFirstLevel from "./MenuFirstLevel";
import MenuSubmenu from "./MenuSubmenu";
import useFeaturedBlock from "../../lib/hooks/use-featured-block";

interface IComponentProps extends IAppComponentProps  {}

const Menu: React.FC<IComponentProps> = ({ className = "", style = {} }) => {
    const { menu } = useContext(AppMenuContext);
    const [currentItem, setCurrentItem] = useState<IAppMenu>({})
    // const isCurrentItemSelected = Object.keys(currentItem).length > 0;
    const SubmenuClass = "MRMenu__Submenu--close";
    const FeaturedBlock = useFeaturedBlock();
    const menuItems = menu.menu? menu.menu.nodes : [];
    const handleClick = useCallback((currentItem: IAppMenu) => {
        setCurrentItem(currentItem);
        console.log('-click-',currentItem.name);
    },
    [setCurrentItem],
    );

    const handleClose = useCallback(() => {
            setCurrentItem({});
        },
        [],
    );

    return (
        <div className={className} style={style}>
            <MenuFirstLevel className="MRMenu__FirstLevel" rootMenu={menu.menu} onClick={handleClick} selectedItem={currentItem.id} />
            {menuItems && menuItems.map((item, i) => {
                const isOpen = item.id === currentItem.id;

                return (<MenuSubmenu key={item.id} className={"MRMenu__Submenu"} currentItem={item} isOpen={isOpen} onClose={handleClose} />)
            })}

            <div className="MRMenu__Feautured" dangerouslySetInnerHTML={{ __html: FeaturedBlock }} />
        </div>
    );
};

export default Menu;
