import { useContext, useEffect, useState } from "react";
import getFeaturedBlock from "../get-featured-block";
import { AppMenuContext } from "../../context/AppMenuProvider";

/**
 * Asynchronously get featured block
 */
const useFeaturedBlock = () => {
    const [featuredBlock, setFeaturedBlock] = useState<string>('');
    const { config } = useContext(AppMenuContext);

    useEffect(() => {
        (async () => {
            const res = await getFeaturedBlock(config);

            const decoded = JSON.parse(res);

            if (decoded.hasOwnProperty('content') && decoded.content) {
                setFeaturedBlock(decoded.content);
            } else {
                setFeaturedBlock('');
            }
        })();
    }, [config]);

    return featuredBlock;
};

export default useFeaturedBlock;
