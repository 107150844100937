/*
 * Copyright © MageSpecialist - Skeeller srl. All rights reserved.
 * See COPYING.txt for license details.
 */
const esES = {
    'Products': 'Nuestros Productos',
    'Show all products': 'Verlos a todos'
};

export default esES;
