import React, {useCallback, useContext} from "react";
import { IAppMenu } from "../../context/AppMenuProvider";
import { IAppComponentProps } from "../../resources/component-interface";
import {LocaleContext} from "../Locale/LocaleProvider";
import MenuItem from "./MenuItem";

interface IComponentProps extends IAppComponentProps  {
    rootMenu?: IAppMenu,
    onClick?: (menuItem: IAppMenu) => void,
    selectedItem?: string;
}

const MenuFirstLevel: React.FC<IComponentProps> = ({ className = "", style = {} , rootMenu, onClick, selectedItem}) => {
    const { t } = useContext(LocaleContext);

    return (
        <div className={className} style={style}>
            <div className="MRMenu__Title">{t('Products')}</div>
            <div className="MRMenu__MenuContent">
                {rootMenu?.nodes?.map((item, i) => {
                    const selectedItemClass = selectedItem === item.id ? 'MRMenu__MenuItem--selected' : '';
                    return <MenuItem key={i} className={"MRMenu__MenuItem " + selectedItemClass} currentItem={item} onClick={onClick}/>
                })}
            </div>
        </div>
    );
}

export default MenuFirstLevel;
