import itIT from "./it_IT";
import esES from "./es_ES";
import deDE from "./de_DE";
import frFR from "./fr_FR";

const lang = new Map<string, Map<string, string>>();

lang.set("it_IT", new Map<string, string>(Object.entries(itIT)));
lang.set("es_ES", new Map<string, string>(Object.entries(esES)));
lang.set("de_DE", new Map<string, string>(Object.entries(deDE)));
lang.set("fr_FR", new Map<string, string>(Object.entries(frFR)));

export default lang;
