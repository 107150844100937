/*
 * Copyright © MageSpecialist - Skeeller srl. All rights reserved.
 * See COPYING.txt for license details.
 */
const itIT = {
    'Products': 'I nostri prodotti',
    'Show all': 'Vedi tutte le categorie',
    'Show all products': 'Vedi tutti'
};

export default itIT;
