import {useState} from "react";
import { IAppConifgMenu } from "../../context/AppMenuProvider";

const useMenu = <T>(): IAppConifgMenu => {

    const [menu, setMenu] = useState(() => {
        try {
            const menuObject = window.enhancedMenu ? JSON.parse(window.enhancedMenu.menu) : {};
            return { menu: menuObject }
        } catch (error) {
            console.error(error);
            return { menu: {} };
        }
    });

    return menu;
};

export default useMenu;
