import React, { useCallback } from "react";
import { IAppMenu } from "../../context/AppMenuProvider";
import { IAppComponentProps } from "../../resources/component-interface";

interface IComponentProps extends IAppComponentProps  {
    currentItem: IAppMenu,
    onClick?: (menuItem: IAppMenu) => void;
}

const MenuItem: React.FC<IComponentProps> = ({ className = "", style = {} , currentItem, onClick}) => {
    const handleClick = useCallback(() => {
        onClick && onClick(currentItem);
    }, [currentItem, onClick]);

    return (
        <div className={className} key={currentItem.id} onClick={handleClick}>
            <div className="MRMenu__MenuItemContent">
                <span className={"MRMenu__Text"}>{currentItem.name}</span>
                <i className="MRMenu__Icon mel-icon-arrow_right"></i>
            </div>
        </div>
    );
}

export default MenuItem;
