import { useCallback, useEffect, useState } from "react";

export interface IWindowSize {
    isMobileScreenWidth: boolean;
}

const useWindowSize = (cb?: (size: IWindowSize) => void): IWindowSize => {

    const getWindowSize = (): IWindowSize => {
        const res = {
            isMobileScreenWidth: window.innerWidth < 768,
        };

        return res;
    };

    const [size, setSize] = useState<IWindowSize>(getWindowSize());

    const onResize = useCallback(() => {
        const newSize = getWindowSize();

        cb && cb(newSize);
        setSize(newSize);
    }, [cb]);

    useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, [onResize]);

    return size;
};

export default useWindowSize;
