/*
 * Copyright © MageSpecialist - Skeeller srl. All rights reserved.
 * See COPYING.txt for license details.
 */
const frFR = {
    'Products': 'Nos Produits',
    'Show all products': 'Voir tous'
};

export default frFR;
