import React from 'react';
import './App.css';
import Menu from './components/Menu/Menu';
import LocaleProvider from "./components/Locale/LocaleProvider";
import AppMenuProvider, {IAppConfig} from './context/AppMenuProvider';

interface IAppProps {
    config: IAppConfig;
}

const App: React.FC<IAppProps> = ({ config }) => {
  return (
    <AppMenuProvider config={config} >
        <LocaleProvider>
            <Menu className={"MRMenu"} />
        </LocaleProvider>
    </AppMenuProvider>
  );
};

export default App;

