/*
 * Copyright © MageSpecialist - Skeeller srl. All rights reserved.
 * See COPYING.txt for license details.
 */
const deDE = {
    'Products': 'Unsere Produkte',
    'Show all products': 'Sehen Sie sie alle'
};

export default deDE;
