import React, {useCallback, useContext, useEffect, useState} from "react";
import {AppMenuContext, IAppMenu} from "../../context/AppMenuProvider";
import { IAppComponentProps } from "../../resources/component-interface";
import useWindowSize from "../../lib/hooks/use-window-size";
import {LocaleContext} from "../Locale/LocaleProvider";

interface IComponentProps extends IAppComponentProps  {
    currentItem: IAppMenu,
    isOpen: boolean,
    onClose: () => void,
}

const MenuSubmenu: React.FC<IComponentProps> = ({ className = "", style = {}, currentItem, isOpen = false, onClose }) => {
    const [isOpenItem, setIsOpenItem] = useState(false);
    const { t } = useContext(LocaleContext);
    const scaleX = isOpenItem ? 'scaleX(1)' : 'scaleX(0)';
    const { config } = useContext(AppMenuContext);
    const baseUrl = config.baseUrl.slice(0, -1)
    const submenuStyle = {
        transform: scaleX,
    } as React.CSSProperties;
    const { isMobileScreenWidth } = useWindowSize();

    const handleClick = useCallback(() => {
        setIsOpenItem(false);
        onClose && onClose();
    }, [setIsOpenItem]);

    const handleLink = () => {
        if(currentItem.url) {
            window.location.href = config.baseUrl.slice(0, -1) + currentItem.url;
        }
    }

    const handleWrap = () => {
        if(isMobileScreenWidth) {
            handleClick();
        } else {
            handleLink();
        }
    }

    useEffect(() => {
        if(isOpen) {
            setIsOpenItem(true);
        } else {
            setIsOpenItem(false);
        }
    }, [isOpen, setIsOpenItem]);

    const resultLimit = 14;
    const showAllCategories = currentItem.nodes ? !isMobileScreenWidth && currentItem.nodes.length > resultLimit : false;

    return (
        <div className={className} style={submenuStyle}>
            <div className="MRMenu__Title" onClick={handleWrap}>
                {isMobileScreenWidth &&
                    <i className="MRMenu__Icon mel-icon-arrow_left mel-icon--size_24"></i>
                }
                {currentItem.name}
            </div>
            <div className="MRMenu__SubmenuContent">
                {isMobileScreenWidth && <span className="MRMenu__AllCategories"  onClick={handleLink}>{t('Show all products')}</span>}
                {currentItem.nodes?.slice(0, resultLimit).map((item) => {
                    return <div className="MRMenu__MenuItem" key={item.id}>
                        <div className="MRMenu__Text" key={item.id}>
                            <a className="MRMenu__Link" href={baseUrl + item.url} title="">
                                {item.name}
                            </a>
                        </div>
                    </div>
                })}
                {showAllCategories && <span className="MRMenu__AllCategories"  onClick={handleLink}>{t('Show all')}</span>}
            </div>
        </div>
    );
}

export default MenuSubmenu;
