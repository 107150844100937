import * as React from "react";
import useMenu from "../lib/hooks/use-menu";

export interface IAppConifgMenu {
    menu?: IAppMenu;
}

export interface IAppConfig {
    baseUrl: string,
    locale: string,
    currency: string
}

export interface IAppMenu {
    name?: string;
    id?: string;
    url?: string;
    path?: string;
    level?: string;
    nodes?: IAppMenu[]
}

interface IContextProps {
    menu: IAppConifgMenu;
    config: IAppConfig;
}

export const AppMenuContext = React.createContext<IContextProps>({} as IContextProps);

interface IContextProviderProps {
    config: IAppConfig;
    children: React.ReactNode;
}

const AppMenuProvider = (props: IContextProviderProps) => {
    const menu = useMenu();

    return (
        <AppMenuContext.Provider
            value={{
                menu: menu,
                config: props.config
            }}
        >
            {props.children}
        </AppMenuContext.Provider>
    );
};

export default AppMenuProvider;
